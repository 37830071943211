import { FormConfigItem } from 'components/CommonFormCreate/interface'
import { FormTypes } from 'components/CommonFormCreate/FormTypes'
import { reasonOptions } from './Options'
import React from 'react'
import { computedProfit } from './utils'

export const formConfig = (form:any, capitalForm:any):FormConfigItem[] => {
  return [
    // 开仓方向
    {
      type: FormTypes.RADIO,
      label: '交易方向',
      name: 'tradeDirection',
      required: true,
      options: [
        {
          label: '开多',
          value: '1',
        },
        {
          label: '开空',
          value: '2',
        },
      ]
    },
    // 当前账户资金
    {
      type: FormTypes.INPUT_NUMBER,
      label: '当前账户资金',
      name: 'currentCapital',
      required: true,
      autoPlaceholder: true,
      style: { width: '100%' }
    },
    {
      type: FormTypes.TEXTAREA,
      label: '备注',
      name: 'remark',
      required: false,
      autoPlaceholder: true,
      style: { width: '100%' }
    },
  ]
}
