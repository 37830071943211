
import React, { useEffect, useState } from 'react'
import { CommonFormCreate } from 'components/CommonFormCreate'
import {formConfig} from './FormConfig'
import { useFormRef } from 'components/CommonFormCreate/useFormRef'
import { commonApi } from 'api/common'
import { Button, Table,message,Drawer, Row, Col, Select} from 'antd'
import { EditOutlined } from '@ant-design/icons';
import {columns} from './ListColumns'
import { FormTypes } from 'components/CommonFormCreate/FormTypes'
import { css } from '@emotion/react'
import { computedProfitRate, computedWinRate } from './utils'
import type { DatePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';
import { Collapse } from 'antd';
import { TheEchart } from './TheEchart'
import { reasonOptions } from './Options'
import axios from 'axios';
import { computedProfit, computedFinalProfit, computedAccountProfit } from './utils'

const { Panel } = Collapse;
interface Capital {
  current: number,
  fixed: number,
  reflect: number,
}


const useLocalState = <T,>(key: string, defaultValue: T) => {
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
    return defaultValue
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [state, key])

  return [state, setState] as const
}


export const Main = () => {
  const [open, setOpen] = useState(false)
  const [list, setList] = useLocalState('list', [])
  const [form, setForm] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [capitalForm, setCapitalForm] = useState<Capital>({
    current:0,
    fixed: 0,
    reflect: 0
  })
  const formRef = useFormRef<any>()
  const formRef2 = useFormRef<any>()


  useEffect(() => {
    getTradeList()
    getTradingCapital()
  }, [])

  const onAdd = async () => {
    try {
      setLoading(true)
      await formRef.current?.validateFields()
      await commonApi.postTrade(form)
      setLoading(false)
      message.success('交易记录创建成功');
      getTradeList()
      getTradingCapital()
      setForm({
        openPrice: '',
        closePrice: '',
        openAmount: '',
        tradeDirection: '',
        currentCapital: '',
        remark: '',
        reason: '',
      })
      setOpen(false)
      updateCapital()
    } catch (error) {
      setLoading(false)
    }
  }

  const getTradeList = async () => {
    const { data } = await commonApi.getTradeList()
    setList(data.result.list)
  }

  const getTradingCapital = async () => {
    const { data } = await commonApi.getTradingCapital()
    setCapitalForm(data.result)
  }

  const onSetTradingCapital = async () => {
    await formRef2.current?.validateFields()
    await commonApi.postSetTradingCapital(capitalForm)
    message.success('交易资金修改成功');
    updateCapital()
  }

  const updateCapital = ()=> {
    axios('http://yhkk99.lafhk.gy2702.cn:80/getCaptail')
  }

  interface FilterForm {
    // 月份
    month: number | undefined,
    reason: string | undefined,
  }
  const [filterForm, setFilterForm] = useState<FilterForm>({
    month: Date.now(),
    reason: undefined,
  })
  const [currentList, setCurrentList] = useState<any>([])
  useEffect(() => {
    let newList = list
    if(filterForm.month) {
      const year = new Date(filterForm.month).getFullYear()
      const month = new Date(filterForm.month).getMonth() + 1
      newList = list.filter((item: any) => {
        const createdAt = new Date(item.created_at)
        const createdAtYear = createdAt.getFullYear()
        const createdAtMonth = createdAt.getMonth() + 1
        console.log(createdAtYear, year, createdAtMonth, month);
        return createdAtYear === year && createdAtMonth === month
      })
    }
    if(filterForm.reason) {
      newList = newList.filter((item: any) => {
        return item.reason === filterForm.reason
      })
    }
    setCurrentList(newList)
  }, [filterForm, list])

  return (
    <div  css={css
      `
        padding: 30px
      `
      }>
      <Button
        type="primary"
        shape="circle"
        onClick={()=> setOpen(true)} icon={<EditOutlined />}
        size="large"
        css={css
        `
          position: fixed;
          z-index: 999;
          bottom: 50px;
          right: 50px;
        `
        }
      />
      <Drawer title="" placement="right" onClose={()=> setOpen(false)} visible={open}>
        <div  css={css
        `
          margin-bottom: 30px;
        `
        }>
          <Collapse defaultActiveKey={[]}>
            <Panel header="修改交易资金" key="1">
              <CommonFormCreate<Capital>
                formItemLayout={{
                  labelCol: { span: 10 },
                  wrapperCol: { span: 14 },
                }}
                value={capitalForm}
                formConfig={[
                  {
                    type: FormTypes.INPUT_NUMBER,
                    label: '提现资金',
                    name: 'reflect',
                    required: true,
                    autoPlaceholder: true,
                    style: { width: '100%' }
                  },
                  {
                    type: FormTypes.INPUT_NUMBER,
                    label: '备用资金',
                    name: 'fixed',
                    required: true,
                    autoPlaceholder: true,
                    style: { width: '100%' }
                  },
                  {
                    type: FormTypes.INPUT_NUMBER,
                    label: '账户资金',
                    name: 'current',
                    required: true,
                    autoPlaceholder: true,
                    style: { width: '100%' }
                  },
                ]}
                onValuesChange={(changedValues) => {
                  setCapitalForm({
                    ...capitalForm,
                    ...changedValues
                  })
                }}
                cRef={formRef2}
              />
                <Button onClick={onSetTradingCapital} block >修改交易资金</Button>
            </Panel>
          </Collapse>
        </div>
        <CommonFormCreate<any>
          formItemLayout={{
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          }}
          value={form}
          formConfig={formConfig(form, capitalForm)}
          onValuesChange={(changedValues) => {
            setForm({
              ...form,
              ...changedValues
            })
          }}
          cRef={formRef}
        />
        <Button type="primary" loading={loading} onClick={onAdd} block>确认</Button>
      </Drawer>
      <Row gutter={[16, 16]}>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>已提现资金</div>
            <div>{ capitalForm.reflect }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>备用资金</div>
            <div>{ capitalForm.fixed }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>账户资金</div>
            <div>{ capitalForm.current }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>交易总资金</div>
            <div>{ capitalForm.current + capitalForm.fixed }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>下次开仓金额</div>
            <div>{ ((capitalForm.current + capitalForm.fixed) * 0.02 / 0.0055 ).toFixed(0) }</div>
            <div css={css
            `
              font-size: 10px;
              opacity: 0.6
            `
            }>预计亏损: { ((capitalForm.current + capitalForm.fixed) * 0.02).toFixed(0) }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>总盈利</div>
            <div>{ list.reduce((total:any, item:any)=> {
              return computedAccountProfit(item) + total
            }, 0).toFixed(0) }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>筛选盈利</div>
            <div>{ currentList.reduce((total:any, item:any)=> {
              return computedAccountProfit(item) + total
            }, 0).toFixed(0) }</div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>盈亏比</div>
            <div>
              { computedProfitRate(currentList) }
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div css={theCss}>
            <div>胜率</div>
            <div>{ computedWinRate(currentList) }%</div>
          </div>
        </Col>
      </Row>
      <div css={css
      `
        margin-top: 30px;
      `
      }>
       <Collapse css={css
          `
          margin-bottom: 30px;
        `
          } defaultActiveKey={[]}>
          <Panel header="收益曲线图" key="1"><TheEchart list={currentList} /></Panel>
        </Collapse>
       <CommonFormCreate<FilterForm>
          layout='inline'
          value={filterForm}
          formItemLayout={{
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
          }}
          formConfig={[
            {
              // 月份
              type: FormTypes.DATE_SELECT,
              label: '月份',
              name: 'month',
              autoPlaceholder: true,
              style: { width: '100%' },
              picker: 'month'
            },
            // 原因
            {
              type: FormTypes.SELECT,
              label: '原因',
              name: 'reason',
              autoPlaceholder: true,
              options: reasonOptions,
            }
          ]}
          onValuesChange={(changedValues) => {
            setFilterForm({
              ...filterForm,
              ...changedValues
            })
          }}
          cRef={formRef}
        />
      <Table  dataSource={currentList} columns={columns()} />
      </div>
    </div>
  )
}

const theCss = css`
  background: #0092ff;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 16px;

`
