import * as React from 'react'
import { LoginMain } from './LoginMain'
import { css } from '@emotion/react'
import { useAuth } from 'context/authContext'
import image from '../../undraw_teaching_re_g7e3.svg'

// 登录页面
export const Login = () => {
  const { login } = useAuth()

  return (
    <>
      <div css={style.body}>
        <div css={style.main}>
          <LoginMain login={login} />
        </div>
      </div>
    </>
  )
}

const style = {
  body: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  main: css`
    width: 350px;
    min-width: 350px;
    padding: 70px 50px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    margin-bottom: 30px;
    position: relative;
    margin-left: 200px;
    &::before {
      content: '';
      position: absolute;
      width: 90%;
      height: 80%;
      top: 50%;
      transform: translateY(-50%);
      left: -85%;
      z-index: -1;
      background: #fff;
      background: #1990ff url(${image}) no-repeat;
      border-radius: 20px;
      background-position: center;
      background-size: 80%;
    }
    `,
}
