import { requestErrorHandler } from './requestErrorHandler'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from 'config'
import { notification } from 'antd'
import { token } from 'utils/token'

// 创建 axios 实例
export const request = axios.create({
  baseURL: config.baseUrl,
  timeout: 20000, // 请求超时时间
})

// 请求拦截器
request.interceptors.request.use(
  (config:AxiosRequestConfig) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const localToken = token.get()
    localToken && config.headers && (config.headers.Authorization = 'Bearer ' + localToken)
    return {
      ...config,
      params: {
        ...(config.params || {}),
        _t: Date.now(), // 每次发送请求时，都附加一个时间戳, 防止请求缓存
      },
    }
  },
  error => {
    return Promise.reject(error)
  },
)

// 响应拦截器
request.interceptors.response.use(
  (response:AxiosResponse) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到后端数据
    if (response.status === 200) {
      if (response.data.code === 0) {
        return Promise.resolve(response)
      } else {
        if(response.data.code === 401) {
          token.clear()
          // 刷新页面
          window.location.reload()
        }
        notification.error({
          message: response.data.error,
        })
        return Promise.reject(response)
      }
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    return requestErrorHandler(error)
  },
)
