import * as Antd from 'antd'
import React, { useEffect } from 'react'
import moment, { Moment } from 'moment'

export const MyTimePicker = ({
  onChange,
  value,
  ...props
}: Antd.TimePickerProps & {
  onChange?: (formatTime: string) => void;
  value?: string;
}) => {
  const [currentValue, setCurrentValue] = React.useState<Moment | null>()
  useEffect(() => {
    if (value) {
      setCurrentValue(moment(value, props.format as string))
    } else {
      setCurrentValue(null)
    }
  }, [value, props.format])
  return (
    <Antd.TimePicker
      value={currentValue}
      {...props}
      onChange={(time) => {
        if (time) {
          const formatTime = time?.format(props.format as string)
          console.log(formatTime)
          onChange && onChange(formatTime)
        }
      }}
    />
  )
}
