import React from 'react'
import { useAuth } from 'context/authContext'
import { Login } from './views/Login'
import { Navigate } from 'react-router'

// 路由拦截组件
const RequireAuth = ({ children }: { children:React.ReactNode }) => {
  const { user } = useAuth()
  return user?.token ? <Navigate to='/' /> : <>{children}</>
}

export const UnAuthApp = () => {
  return (
    <>
      <RequireAuth>
        <Login />
      </RequireAuth>
    </>
  )
}
