import * as Antd from 'antd'
import React, { useImperativeHandle, MutableRefObject, useEffect } from 'react'
import { FormItem } from './FormItem'
import { FormConfigItem } from './interface'
import { FormTypes } from './FormTypes'

interface CommonFormCreateProps<T, > extends Antd.FormProps {
  value: T | null,
  formConfig: FormConfigItem[],
  onValuesChange?: (values: T) => void
  cRef?: MutableRefObject<{
    validateFields: () => Promise<T>
  } | null>
  formItemLayout?: {
    labelCol: Antd.ColProps
    wrapperCol: Antd.ColProps
  } | null
}

const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
}

// 根据json配置生成表单
export const CommonFormCreate = <T, >(
  {
    value, formConfig, onValuesChange, cRef, formItemLayout = defaultFormItemLayout, ...props
  }:CommonFormCreateProps<T>,
) => {
  const [form] = Antd.Form.useForm()

  useEffect(() => {
    form.setFieldsValue(value)
  }, [form, value])

  useImperativeHandle(cRef, () => ({
    validateFields: () => form.validateFields(),
  }))

  const dealFormConfigItem = (item: FormConfigItem) => {
    const formItemWrapProps = { ...item }
    const formItemProps = { ...item }
    const delKeys = ['autoSize']
    delKeys.forEach(key => {
      delete formItemWrapProps[key]
    })
    // 根据type不同设置不同title: 请输入, 请选择
    const selectType = [FormTypes.SELECT]
    let title: string
    if (selectType.includes(formItemWrapProps.type)) {
      title = '请选择'
    } else {
      title = '请输入'
    }
    if (formItemWrapProps.required === true) {
      const rule = {
        required: true,
        message: title + item.label,
      }
      formItemWrapProps.rules ? formItemWrapProps.rules.unshift(rule) : formItemWrapProps.rules = [rule]
    }
    if (formItemProps.autoPlaceholder === true) {
      formItemProps.placeholder = title + item.label
    }
    return {
      formItemWrapProps,
      formItemProps,
    }
  }

  const listRender = () => {
    return formConfig.map((item: FormConfigItem) => {
      if (!item.type) return null
      const {
        formItemWrapProps,
        formItemProps,
      } = dealFormConfigItem(item)

      if (props.layout === 'inline') {
        const span = {
          xs: formItemWrapProps.span || 24,
          sm: formItemWrapProps.span || 12,
          md: formItemWrapProps.span || 8,
          lg: formItemWrapProps.span || 8,
          xl: formItemWrapProps.span || 6,
          xxl: formItemWrapProps.span || 4,
        }
        return (
          <Antd.Col
            key={formItemWrapProps.name}
            {...span}
          >
            <Antd.Form.Item {...formItemWrapProps}>
              {FormItem<T>(formItemProps, value)}
            </Antd.Form.Item>
          </Antd.Col>
        )
      } else {
        return (
          <Antd.Col
            key={formItemWrapProps.name}
            span={formItemWrapProps.span || 24}
          >
            <Antd.Form.Item {...formItemWrapProps}>
              {FormItem<T>(formItemProps, value)}
            </Antd.Form.Item>
          </Antd.Col>
        )
      }
    })
  }
  return (
    <Antd.Form
      form={form}
      onValuesChange={onValuesChange}
      scrollToFirstError
      {...formItemLayout}
    >
      <Antd.Row gutter={24}>
        {listRender()}
      </Antd.Row>
    </Antd.Form>
  )
}

