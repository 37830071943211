
import { TableProps, message, Button, Tooltip } from 'antd'
import React from 'react'
import { css } from '@emotion/react'
import { computedProfit, computedFinalProfit, computedAccountProfit, computedFee, computedRebateFee } from './utils'
import dayjs from 'dayjs'
import { Tag } from 'antd'
import { reasonOptions } from './Options'
import { CopyOutlined } from '@ant-design/icons'

export const columns = ():TableProps<any>['columns'] => {
  return [
    {
      // id
      title: 'ID',
      dataIndex: '_id',
      // 复制功能
      render(value, record, index) {
        const onClick = () => {
          navigator.clipboard.writeText(record._id)
          message.success('复制成功')
        }
        return <div>
          <Button  onClick={onClick} type="link" shape="circle" icon={<CopyOutlined />} />
        </div>
      },
    },
    {
      title: '开仓价格/平仓价',
      dataIndex: 'openPrice',
      render(value, record, index) {
        return  record.tradeDirection === '1' ? <Tag color="green">开多</Tag> : <Tag color="red">开空</Tag>
      },
    },

    {
      title: '账户盈亏',
      dataIndex: 'profit',
      render(value, record, index) {
        return computedAccountProfit(record)
      },
    },
    {
      title: '平仓后账户资金',
      dataIndex: 'profit',
      render(value, record, index) {
        return <div>
          <span css={css
          `
            text-decoration:line-through;
            margin-right: 10px;
            color: #999;
          `
          }>{record.prevCurrentCapital}</span>
          <span>{record.currentCapital}</span>
        </div>
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      render(value, record, index) {
        /* 鼠标移入显示全部 */
        /* Tooltip */
        return <Tooltip title={value}>
          <div css={css`max-width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;`}>{value}</div>
        </Tooltip>
      }
    },
    {
      title: '时间',
      dataIndex: 'created_at',
      render(value, record, index) {
        return dayjs(value).format('MM-DD')
      },
    },
  ]
}
