import { FormConfigItem } from './interface'
import { CommonFormCreate } from './index'
import React from 'react'

export const ListForm = ({
  listValue,
  formConfig,
  onChange,
}: {
  listValue: { [key: string]: unknown }[];
  onChange?: (listValue: { [key: string]: unknown }[]) => void;
  formConfig: FormConfigItem[];
}) => {
  return (
    <>
      {listValue.map((item, index) => {
        return (
          <div key={index}>
            <CommonFormCreate
              formItemLayout={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 },
              }}
              onValuesChange={(itemValue) => {
                const copyListValue = [...listValue]
                copyListValue[index] = {
                  ...copyListValue[index],
                  ...itemValue,
                }
                onChange && onChange(copyListValue)
              }}
              value={item}
              formConfig={formConfig}
            />
            <button
              onClick={() => {
                const copyListValue = [...listValue]
                copyListValue.splice(index, 1)
                onChange && onChange(copyListValue)
              }}
            >
              删除
            </button>
          </div>
        )
      })}
      <button
        onClick={() => {
          onChange && onChange([...listValue, {}])
        }}
      >
        加
      </button>
    </>
  )
}

