import { useDebounce } from 'utils/useDebounce'
import { AxiosResponse } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { OptionsItem } from '../interface'

// 获取远程搜索数据
export const useSearchOptions = (searchValue: string, searchApi?: ({ value }: {value:string}) => Promise<AxiosResponse>) => {
  const [searchOptions, setSearchOptions] = useState<OptionsItem[]>([])
  const debounceSearchValue = useDebounce(searchValue, 500)
  const lastFetchId = useRef(0)
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    const getSearchOptions = async () => {
      if (searchApi && debounceSearchValue) {
        lastFetchId.current++
        setSearchOptions([])
        setIsSearching(true)
        const fetchId = lastFetchId.current
        try {
          const { data } = await searchApi({ value: debounceSearchValue })
          if (fetchId === lastFetchId.current) {
            setSearchOptions(data.result.map((item: any) => ({
              value: item.id,
              label: item.name,
              ...item,
            })))
          }
        } catch (e) {
          console.log(e)
        } finally {
          setIsSearching(false)
        }
      }
    }
    if (searchApi) {
      getSearchOptions()
    }
  }, [searchApi, debounceSearchValue])

  return [searchOptions, isSearching] as const
}
