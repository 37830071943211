
// 计算理论盈利
export const computedProfit = (record: any)=> {
    try {
      const profit = (record.closePrice - record.openPrice) / record.openPrice * record.openAmount
      if(record.tradeDirection === '2') {
        return -parseFloat(profit.toFixed(2))
      } else {
        return parseFloat(profit.toFixed(2))
      }
    } catch (error) {
      return 0
    }
}

// 计算账户盈利
export const computedAccountProfit = (record: any)=> {
   return Number((record.currentCapital - record.prevCurrentCapital).toFixed(2))
}

// 计算最终盈利
export const computedFinalProfit = (record: any)=> {
  return Number((computedAccountProfit(record) + computedRebateFee(record)).toFixed(2))
}

// 计算手续费
export const computedFee = (record: any)=> {
   return Number((computedProfit(record) - computedAccountProfit(record)).toFixed(2))
}

// 计算手续费返利
export const computedRebateFee = (record: any)=> {
   return Number((computedFee(record) / 0.9 * 0.25).toFixed(2))
}

// 计算盈亏比
export const computedProfitRate = (currentList: any[])=> {
  const result = ((currentList.reduce((total:any, item:any)=> {
    if(item.currentCapital - item.prevCurrentCapital < 0) {
      return total
    } else {
      return computedAccountProfit(item) + total
    }
  }, 0) / currentList.filter((item:any)=> item.currentCapital - item.prevCurrentCapital>0).length) / (-currentList.reduce((total:any, item:any)=> {
    if(item.currentCapital - item.prevCurrentCapital > 0) {
      return total
    } else {
      return computedAccountProfit(item) + total
    }
  }, 0) / currentList.filter((item:any)=> item.currentCapital - item.prevCurrentCapital < 0).length))

  // 判断是否为NaN
  if(Number.isNaN(result)) {
    return 0
  } else {
    return result.toFixed(2)
  }
}

// 计算胜率
export const computedWinRate = (currentList: any[])=> {
  const value = parseFloat((currentList.filter((item:any)=> item.currentCapital - item.prevCurrentCapital>0).length / currentList.length).toFixed(2)) * 100
  if(Number.isNaN(value)) {
    return 0
  } else {
    return value.toFixed(0)
  }
}
