import React from 'react'
import * as Antd from 'antd'
import { FormTypes } from './FormTypes'
import { FormConfigItem } from './interface'
import { CommonSelect } from './CommonSelect'
import { ListForm } from './ListForm'
import { MyTimePicker } from './MyTimePicker'
import { MyDatePicker } from './MyDatePicker'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

export const FormItem = <T, >(item: FormConfigItem, value: T | null) => {
  const { type, options, ...rest } = item

  switch (type) {
    case FormTypes.INPUT:
      return (
        <Antd.Input {...rest} />
      )
    case FormTypes.INPUT_NUMBER:
      return (
        <Antd.InputNumber {...rest} />
      )
    case FormTypes.TEXTAREA:
      return (
        <Antd.Input.TextArea {...rest} />
      )
    case FormTypes.SELECT:
      return (
        <CommonSelect {...rest} options={options || []} />
      )
    case FormTypes.DATE_SELECT:
      return (
        <MyDatePicker {...rest} />
      )
    case FormTypes.TIME_SELECT:
      return (
        <MyTimePicker {...rest} />
      )
    case FormTypes.LIST:
      return (
        value && item.name && item.formConfig && (
          <ListForm
            listValue={(value as {[key:string]: any})[item.name] as {[key:string]: unknown}[]}
            formConfig={item.formConfig}
          />
        )
      )
    case FormTypes.RADIO:
      return (
        <Antd.Radio.Group>
          {options && options.map((item: any) => (
            <Antd.Radio.Button value={item.value} key={item.value}>
              {item.label}
            </Antd.Radio.Button>
          ))}
        </Antd.Radio.Group>
      )
    case FormTypes.CHECKBOX:
      return (
        <Antd.Checkbox.Group>
          {options && options.map((item: any) => (
            <Antd.Checkbox value={item.value} key={item.value}>{item.label}</Antd.Checkbox>
          ))}
        </Antd.Checkbox.Group>
      )
      case FormTypes.RATE:
        const customIcons: Record<number, React.ReactNode> = {
          1: <FrownOutlined />,
          2: <FrownOutlined />,
          3: <MehOutlined />,
          4: <SmileOutlined />,
          5: <SmileOutlined />,
        };
        return (
          <Antd.Rate character={({ index }: { index: number }) => customIcons[index + 1]}  />
        )
        case FormTypes.TEXT:
          return (
            <div>{ rest.jsx }</div>
          )

    // case 'date':
    //   return (
    //     <Antd.DatePicker />
    //   )
    // case 'rangePicker':
    //   return (
    //     <Antd.DatePicker.RangePicker />
    //   )
    // case 'timePicker':
    //   return (
    //     <Antd.TimePicker />
    //   )
    // case 'timePickerRange':
    //   return (
    //     <Antd.TimePicker.RangePicker />
    //   )
    // case 'cascader':
    //   return (
    //     <Antd.Cascader />
    //   )
    // case 'switch':
    //   return (
    //     <Antd.Switch />
    //   )
    // case 'slider':
    //   return (
    //     <Antd.Slider />
    //   )
    // case 'inputNumber':
    //   return (
    //     <Antd.InputNumber />
    //   )

    // case 'upload':
    //   return (
    //     <Antd.Upload />
    //   )
    default:
      return null
  }
}
