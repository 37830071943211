import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { AppProviders } from 'context'
import { BrowserRouter as Router } from 'react-router-dom'
import zhCN from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
// import './virtual:windi.css'

ReactDOM.render(
  <>
    <Router basename='/'>
      <AppProviders>
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </AppProviders>
    </Router>,
  </>,
  document.getElementById('root'),
)
