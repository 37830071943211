import { OptionsItem } from 'components/CommonFormCreate/interface'

export const reasonOptions: OptionsItem[] = [
  {
    label: '短线突破信号',
    value: '1',
  },
  {
    label: '感觉',
    value: '2',
  },
  {
    label: '财神信号',
    value: '3',
  },
  {
    label: 'K大信号',
    value: '4',
  }
]
