import { request } from './utils/request'
import { LoginForm, CodeForm } from 'utils/interface'

export const authApi = {
  login (data: LoginForm) {
    return request.post('/admin-login', data)
  },
  logout () {
    return request.post('/logout')
  },
  sendCode (data: CodeForm) {
    return request.post('/message/send', data)
  },
  getUserInfo () {
    return request.get('/user/info')
  },
  getServerNav () {
    return request.get('/user/nav')
  },
}
