import { AuthApp } from 'AuthApp'
import React, { Suspense, useEffect } from 'react'
import { UnAuthApp } from 'UnAuthApp'
import { Routes, Route } from 'react-router'
import { useAuth } from 'context/authContext'
import { NavItem } from 'utils/interface'
// import { useAuth } from 'context/authContext'
import { componentMap } from 'componentMap'
import NProgress from 'nprogress'
import { css } from '@emotion/react'
import welcomeBg from './learning-g677f26558_1920.jpg'

const Welcome = () => {
  return (
    <div css={
    css`
    background-image: url(${welcomeBg});
    background-size: cover;
    height: calc(100vh - 170px);
    transform:scale(1.07);
    text-align: center;
    font-weight:600;
    font-size: 78px;
    padding-top: 200px;
    color: #1890ff;
    `
  }
    >
      <div css={
        css`
          background: #fff;
        `
      }
      >
        欢迎使用教室预约系统
      </div>
    </div>
  )
}

// 进度条
const Progress = () => {
  useEffect(() => {
    NProgress.start()
    return () => {
      NProgress.done()
    }
  }, [])
  return null
}

const BeforeRouterEach = ({ router, children }:{router: NavItem, children: React.ReactNode }) => {
  useEffect(() => {
    console.log('router', router)
    document.title = router.meta.title
  }, [router])
  return <>{children}</>
}

const renderMenuItem = (item: NavItem) => {
  const Element = componentMap[item.component]
  return (
    <Route
      path={item.name}
      key={item.name}
      element={
        <BeforeRouterEach router={item}>
          <Suspense fallback={<><Progress /></>}>
            <Element />
          </Suspense>
        </BeforeRouterEach>
        }
    />
  )
}

function App () {
  console.log('App render')
  const { user } = useAuth()

  return (
    <>
      <Routes>
        <Route path='/' element={<AuthApp />}>
          {user && user.navTree && user.navTree.map(item => {
            if (item.children) {
              return (
                <Route
                  path={item.name} key={item.name}
                >
                  {item.name}
                  {item.children.map(renderMenuItem)}
                </Route>
              )
            }
            return renderMenuItem(item)
          })}
          <Route index element={<Welcome />} />
          <Route path='*' element={<div>404</div>} />
        </Route>
        <Route path='unauth' element={<UnAuthApp />} />
      </Routes>
    </>
  )
}

export default App

