import { useCallback, useEffect, useState } from 'react'

interface State<T> {
  data: T | null
  state: 'idle' | 'loading' | 'success' | 'error'
  error: Error | null
}

// 默认State
const defaultState: State<null> = {
  data: null,
  state: 'idle',
  error: null,
}

export const useAsync = <T>(initialState?: State<T>) => {
  const [state, setState] = useState<State<T>>({
    ...defaultState,
    ...initialState,
  })

  useEffect(() => {
    setState({
      ...defaultState,
      ...initialState,
    })
  }, [initialState])

  const run = async (promise: Promise<T>) => {
    setState(state => ({
      ...state,
      state: 'loading',
    }))
    try {
      const data = await promise
      setState(state => ({
        ...state,
        data,
        state: 'success',
      }))
      return data
    } catch (error) {
      setState(state => ({
        ...state,
        state: 'error',
        error: (error as Error),
      }))
      return Promise.reject(error)
    }
  }

  return {
    ...state,
    isError: state.state === 'error',
    isLoading: state.state === 'loading',
    isSuccess: state.state === 'success',
    run: useCallback(run, []),
  }
}
