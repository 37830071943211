import { useRef, useEffect } from 'react'

// 设置页面标题
export const useDocumentTitle = (title: string) => {
  const oldTitle = useRef(document.title) // 初始标题

  useEffect(() => {
    document.title = title
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.title = oldTitle.current // 卸载时，恢复到初始标题
    }
  }
  , [title])
}
