import React, { useEffect, ReactNode } from 'react'
import * as Antd from 'antd'
import { OptionsItem } from '../interface'
import { AxiosResponse } from 'axios'
import { css } from '@emotion/react'
import { useSearchOptions } from './useSearchOptions'
import { useDictOptions } from './useDictOptions'
import { useCurrentValue } from './useCurrentValue'

// 封装Antd.Select
// 带筛选功能
// 如果props.value是数组，则多选
// 如果props.searchApi存在, 则带远程搜索功能
// 如果props.dictType存在, 则从字典中获取options

export interface CommonSelectProps extends Antd.SelectProps {
  options: OptionsItem[],
  searchApi?: ({ value }: {value:string}) => Promise<AxiosResponse>,
  dictType?: string,
  tableColumns?: {
    title: string,
    dataIndex: string,
    flex?: number,
  }[],
}

export const CommonSelect = ({ value, options, searchApi, dictType, tableColumns, ...otherProps }: CommonSelectProps) => {
  const [searchValue, setSearchValue] = React.useState('') // 搜索框
  const [propsOptions] = React.useState<OptionsItem[]>(options) // 通过props传入的options
  const [searchOptions, isSearching] = useSearchOptions(searchValue, searchApi) // 通过字典api获取的options
  const dictOptions = useDictOptions(dictType) // 通过字典api获取的options
  const [currentOptions, setCurrentOptions] = React.useState<OptionsItem[]>([]) // 当前options
  const [currentValue, valueOptions] = useCurrentValue(value, searchApi) // 通过value中获取options和currentValue

  // 根据不同情况设置currentOptions
  useEffect(() => {
    if (dictType) {
      setCurrentOptions(dictOptions)
    } else if (searchApi) {
      if (valueOptions.length && !searchOptions.length) {
        setCurrentOptions(valueOptions)
      } else {
        setCurrentOptions(searchOptions)
      }
    } else {
      setCurrentOptions(propsOptions)
    }
  }, [dictOptions, searchOptions, propsOptions, valueOptions, searchApi, dictType])

  const dropdownRender = (menu: ReactNode) => {
    return (
      <div>
        {
          tableColumns && (
            <div css={styles.tableColumn} style={{ padding: '12px', fontWeight: 'bold' }}>
              {tableColumns.map(col => (
                <div key={col.dataIndex} style={{ flex: col.flex || 1 }}>
                  {col.title}
                </div>
              ))}
            </div>
          )
        }
        {menu}
      </div>
    )
  }

  const OptionsRender = currentOptions.map((item: OptionsItem) => (
    <Antd.Select.Option key={item.value} value={item.value} label={item.label}>
      {
      tableColumns
        ? (
          <div css={styles.tableColumn}>
            {
              tableColumns.map(col => (
                <div key={col.dataIndex} style={{ flex: col.flex || 1 }}>
                  {item[col.dataIndex]}
                </div>
              ))
            }
          </div>
          )
        : item.label

    }
    </Antd.Select.Option>
  ))

  return (
    <Antd.Select
      mode={Array.isArray(value) ? 'multiple' : undefined}
      allowClear
      dropdownMatchSelectWidth={false}
      value={currentValue}
      optionLabelProp='label'
      filterOption={!searchApi ? (input, option) => option && option.props.children.toLowerCase().includes(input.toLowerCase()) : () => true}
      notFoundContent={<div css={css`text-align: center; padding: 20px 0;`}>{isSearching ? '搜索中...' : '暂无数据'}</div>}
      dropdownRender={dropdownRender}
      showSearch={Boolean(searchApi)}
      onSearch={searchApi ? setSearchValue : undefined}
      {...otherProps}
    >
      {OptionsRender}
    </Antd.Select>
  )
}

const styles = {
  tableColumn: css`
    display: flex;
    padding: 6px 0px;
    > div {
      flex: 1;
      padding: 0 8px;
      white-space: normal;
    }
  `,
}
