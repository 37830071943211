
export const enum FormTypes {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  RADIO = 'RADIO',
  INPUT_NUMBER = 'INPUT_NUMBER',
  SELECT = 'SELECT',
  DATE_SELECT = 'DATE_SELECT',
  DATE_RANGE_SELECT = 'DATE_RANGE_SELECT',
  YEAR_SELECT = 'YEAR_SELECT',
  MONTH_SELECT = 'MONTH_SELECT',
  TIME_SELECT = 'TIME_SELECT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  IMG_UPLOAD = 'IMG_UPLOAD',
  EDITOR = 'EDITOR',
  MAP = 'MAP',
  GROUP = 'GROUP',
  SWITCH = 'SWITCH',
  DURATION_INPUT = 'DURATION_INPUT',
  TEXT = 'TEXT',
  AREA_SELECT = 'AREA_SELECT',
  RATE = 'RATE',
  NATION_SELECT = 'NATION_SELECT',
  TREE_SELECT = 'TREE_SELECT',
  TABLE_SELECT = 'TABLE_SELECT',
  MERGE = 'MERGE',
  CUSTOMIZE = 'CUSTOMIZE',
  CHECKBOX = 'CHECKBOX',
  LIST = 'LIST',
  NONE = 'NONE', // 不显示
}
