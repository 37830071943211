import * as Antd from 'antd'
import React, { useEffect } from 'react'
import moment, { Moment } from 'moment'

export const MyDatePicker = ({
  onChange,
  value,
  valueFormat,
  ...props
}: Antd.DatePickerProps & {
  onChange?: (formatTime: string) => void;
  value?: string;
  valueFormat?: string;
}) => {
  const [currentValue, setCurrentValue] = React.useState<Moment | null>()
  useEffect(() => {
    if (value) {
      setCurrentValue(moment(value, valueFormat))
    } else {
      setCurrentValue(null)
    }
  }, [value, valueFormat])
  return (
    <Antd.DatePicker
      value={currentValue}
      {...props}
      onChange={(time) => {
        if (time) {
          const formatTime = time?.format(valueFormat)
          onChange && onChange(formatTime)
        } else {
          onChange && onChange('')
        }
      }}
    />
  )
}
