// import { useAuth } from 'context/authContext'
import { AppLayout } from 'components/Layout'
import React from 'react'
// import { ProjectList } from './screens/ProjectList'
import { Navigate } from 'react-router'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'context/authContext'

// 路由拦截组件
const RequireAuth = ({ children }: { children:React.ReactNode }) => {
  const { user } = useAuth()
  console.log('RequireAuth render')
  return user && user.token ? <>{children}</> : <Navigate to='/unauth' />
}

export const AuthApp = () => {
  // const { logout } = useAuth()
  const { user } = useAuth()
  return (
    <>
      {/* <button onClick={() => logout()}>登出</button> */}
      {/* <div>登录信息: {user?.token}</div> */}
      {/* <ProjectList /> */}
      <RequireAuth>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </RequireAuth>
    </>
  )
}
