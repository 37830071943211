import { AxiosError } from 'axios'
import { token } from '../../utils/token'
import { notification } from 'antd'

// 处理AxiosError
export const requestErrorHandler = (error: AxiosError) => {
  if (error.response) {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    switch (error.response.status) {
      case 401:
        // 401 清除token信息并跳转到登录页面
        token.clear()
        window.location.reload()
        break
      case 403:
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        token.clear()
        window.location.reload()
        break
      case 500:
        notification.error({
          message: error.response?.data?.message,
          description: error.response?.data?.result?.message,
        })
        return Promise.reject(error)
      default:
        // Antd 错误提示
        notification.error({
          message: '网络错误，请稍后再试！',
        })
        return Promise.reject(error)
    }
  } else {
    // 请求未发送
    notification.error({
      message: '网络错误，请稍后再试！',
    })
    return Promise.reject(error)
  }
}
