import { authApi } from 'api/auth'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { LoginForm, User } from 'utils/interface'
import { serverNavListToTree } from 'utils/serverNav'
import { token } from 'utils/token'
import NProgress from 'nprogress'

const AuthContext = React.createContext<{
  user: User | null,
  login:(form: LoginForm) => Promise<void>
  logout:() => void,
  permission: (permission: string)=> boolean
    } | undefined>(undefined)
AuthContext.displayName = ''

export const AuthProvider = ({ children }:{children:ReactNode}) => {
  const [user, setUser] = useState<User | null>({
    token: token.get() || '',
  })

  const login = async (form: LoginForm) => {
    try {
      const { data } = await authApi.login(form)
      const serverToken = data.result.token
      token.set(serverToken)
      await getUser()
    } catch (e) {
      logout()
    }
  }

  const logout = async () => {
    token.clear()
    setUser(null)
  }

  useEffect(() => {
    token.get() && getUser()
  }, [])

  const getUser = async () => {
    try {
      setUser({
        token: token.get() || '',
      })
    } catch {
      NProgress.set(1)
    }
  }

  const permission = (permission: string) => {
    return !!user?.permissions?.includes(permission)
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, permission }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('请在组件AuthProvider中使用useAuth')
  }
  return context
}
