
import { useEffect, useState } from 'react'
import { CommonSelectProps } from './index'
import { OptionsItem } from '../interface'

// 从value中获取options和currentValue
export const useCurrentValue = (value: CommonSelectProps['value'], searchApi?: CommonSelectProps['searchApi']) => {
  const [valueOptions, setValueOptions] = useState<CommonSelectProps['options']>([])
  const [currentValue, setCurrentValue] = useState<CommonSelectProps['value']>((
    // (value === '' || value === null) ? undefined : value // fix bug: value为空时，不显示placeholder
    value
  ))
  useEffect(() => {
    if (Array.isArray(value)) {
      if (searchApi && value.every(item => typeof item === 'object')) {
        setValueOptions(value.map((item: { id: string | number, name: string }):OptionsItem => ({
          value: item.id,
          label: item.name,
          ...item,
        })))
        setCurrentValue(value.map(item => item.id))
      } else {
        setCurrentValue(value)
      }
    } else {
      if (searchApi && typeof value === 'object') {
        setValueOptions([{
          value: value.id,
          label: value.name,
          ...value,
        }])
        setCurrentValue(value.id)
      } else {
        setCurrentValue(value)
      }
    }
  }, [value, searchApi])
  return [currentValue, valueOptions]
}
