import React from 'react'
import * as echarts from 'echarts/core';
import {
  BarChart,
  // 系列类型的定义后缀都为 SeriesOption
  BarSeriesOption,
  LineChart,
  LineSeriesOption
} from 'echarts/charts';
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  // 数据集组件
  DatasetComponent,
  DatasetComponentOption,
  // 内置数据转换器组件 (filter, sort)
  TransformComponent
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs'

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

const option: ECOption = {
  // ...
};

export const TheEchart = ({list}: {list:any[]}) => {
  const chartRef = React.useRef<HTMLDivElement>(null);
  const [chart, setChart] = React.useState<echarts.ECharts | null>(null);

  React.useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      setChart(chart);
    }
  }, []);

  React.useEffect(() => {
    console.log(1);
    if (chart) {
      const option: ECOption = {
        xAxis: {
          data: [...list].reverse().map(item => dayjs(item.created_at).format('YYYY-MM-DD'))
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'line',
          data: list.map((item,index) => {
            return list.slice(list.length - index - 1).reduce((total:any, item:any)=> {
              return item.currentCapital - item.prevCurrentCapital + total
            }, 0)
          })
        }]
      };
      chart.setOption(option);
    }
  }, [chart, list]);

  return (
    <div
      ref={chartRef}
      style={{ width: '100%', height: 400 }}
    />
  );
}
