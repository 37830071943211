import { commonApi } from 'api/common'
import { OptionsItem } from '../interface'
import { useEffect, useState } from 'react'

// 获取字典数据
export const useDictOptions = (dictType?: string) => {
  const [dictOptions, setDictOptions] = useState<OptionsItem[]>([])

  useEffect(() => {
    const getDictOptions = async () => {
      const storageKey = '__' + dictType + '__'
      try {
        setDictOptions(JSON.parse(localStorage.getItem(storageKey) || '[]'))
      } catch (e) {
      }
      const { data } = await commonApi.getDictionaryOptions({ type: dictType || '' })
      const options = data.result.map((item: {
        id: string | number,
        name: string,
      }) => ({
        value: item.id,
        label: item.name,
      }))
      setDictOptions(options)
      localStorage.setItem(storageKey, JSON.stringify(options))
    }

    if (dictType) {
      getDictOptions()
    }
  }, [dictType])

  return dictOptions
}
