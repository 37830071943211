
import React from 'react'
import {Main} from 'views/Main'

interface Props {
  children?: React.ReactNode
}

export const AppLayout = ({ children } : Props) => {
  return <Main/>
}
