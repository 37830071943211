import { request } from './utils/request'

export const commonApi = {
  getDictionaryOptions (params: {type: string}) {
    return request.get(`sys/dict/detail/list/${params.type}`)
  },
  searchUser (params: any) {
    return request.get('v2/appraise/add/getUser', { params })
  },
  postTrade (body: any) {
    return request.post(`trade-add`, body)
  },
  getTradeList () {
    return request.post('get-trade-list')
  },
  getTradingCapital() {
    return request.post('get-trading-capital')
  },
  postSetTradingCapital(body: {current:number, fixed: number}) {
    return request.post('set-trading-capital', body)
  },

}
