
import * as React from 'react'
import { css } from '@emotion/react'
import { UserOutlined, LockOutlined, CodeOutlined } from '@ant-design/icons'
import { Code } from './Code'
import * as Antd from 'antd'
import { authApi } from 'api/auth'
import { useAsync } from 'utils/useAsync'
import { LoginForm } from 'utils/interface'
import { useDocumentTitle } from 'utils/useDocumentTitle'

export const LoginMain = ({ login }: {login: (form: LoginForm)=> Promise<void> }) => {
  useDocumentTitle('登录')

  const { Form, Input, Button } = Antd
  const [form] = Form.useForm()

  React.useEffect(() => {
    // 设置表单默认值
    form.setFieldsValue({
      username: '',
      password: '',
    })
  }, [])

  // 验证码
  const {
    run: codeRun,
    isLoading: isCodeLoading, // 是否正在发送验证码
    isSuccess: isCodeSuccess, // 是否获取验证码成功
    isError: isCodeError, // 是否获取验证码失败
  } = useAsync()

  const onCodeClick = async () => {
    // 发送验证码
    await codeRun(authApi.sendCode({
      username: form.getFieldValue('username'),
      password: form.getFieldValue('password'),
    }))
  }

  const {
    run: loginRun,
    isLoading: isLoginLoading, // 是否正在登录
  } = useAsync()

  const onFinish = async (values: LoginForm) => {
    // 登录
    await loginRun(login(values))
  }

  const needCode = false

  return (
    <Form
      form={form}
      name='login'
      onFinish={onFinish}
    >
      <h1 css={style.header}>欢迎登录</h1>
      <Form.Item
        name='username'
        rules={[{ required: true, message: '请输入用户名' }]}
      >
        <Input
          prefix={<UserOutlined css={style.inputIcon} />}
          placeholder='请输入用户名'
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[{ required: true, message: '请输入密码' }]}
      >
        <Input
          prefix={<LockOutlined css={style.inputIcon} />}
          type='password'
          placeholder='请输入密码'
        />
      </Form.Item>
      {needCode && (
        <Form.Item
          name='verifyCode'
          rules={[{ required: true, message: '请输入验证码' }]}
        >
          <Input
            prefix={<CodeOutlined css={style.inputIcon} />}
            placeholder='请输入验证码'
            suffix={<Code
              onClick={onCodeClick}
              isLoading={isCodeLoading}
              isError={isCodeError}
              isSuccess={isCodeSuccess}
                    />}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          css={style.button}
          loading={isLoginLoading}
          shape='round'
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}

const style = {
  header: css`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  `,
  button: css`
    width: 100%;
    margin-top: 20px;
  `,
  inputIcon: css`
    margin-left: 2px;
    margin-right: 8px;
  `,
  codeSuccess: css`
    color: #52c41a;
    font-size: 12px;
    margin-top: 6px;
  `,
}
